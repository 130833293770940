import React from "react";
import "./WechatReturn.css";

const WechatReturn = (props) => {
  return (
    <div className="wechat-return">
      <span>Thank you for choosing us!</span>
      <span>
        Your payment request is being verified by our system. Please keep track
        of your account; If it is charged successfully, your policy will be
        issued.
      </span>
      <span>
        For more details, feel free to contact us at{" "}
        <b>info@oshcstudents.com</b>.
      </span>
    </div>
  );
};

export default WechatReturn;
