import { useCallback, useEffect, useState } from "react";
import { API_URL } from "../constants/constant";
import useApiServices from "./useApiServices";

const useLocationAnnouncement = (props) => {
  const { location } = props;
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getService } = useApiServices();

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    getService(API_URL.GET_LOCATION_ANNOUNCEMENT, { location })
      .then((data) => {
        setIsLoading(false);
        setData(data);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });

    setIsLoading(false);
  }, [getService, location]);

  useEffect(() => {
    if (location) fetchData();
  }, [fetchData, location]);

  return {
    data,
    isLoading,
    error,
  };
};

export default useLocationAnnouncement;
