import React, { useEffect } from "react";
// import { Container } from 'reactstrap';
import Header from "./layout/Header";
import MainMenu from "./layout/MainMenu";
import Footer from "./layout/Footer";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <React.Fragment>
      <Header />
      <MainMenu />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
