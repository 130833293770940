import React from "react";
import "./LoginFormModal.css";

function LoginFormModal(props) {
  const { isShowing = false } = props;
  return (
    <div className={`sub-menu ${isShowing ? "sub-menu-active" : ""}`}>
      <div className="content-wrap">
        <form
          id="oshc_nav_login_mobile"
          className="login_agent"
          name="login_agent"
          method="POST"
          action="https://oshcstudents.com.au/login-member/"
        >
          <div className="cover-spin abs"></div>
          <div style={{ textAlign: "center" }}>
            <span className="oshc-error-note login-false"></span>
          </div>
          <p className="row-field">
            <input
              name="menber_username"
              value=""
              type="text"
              id="username_m"
              placeholder="Username or email"
            />
            <span className="oshc-error-note">
              Please enter a valid username
            </span>
          </p>
          <p className="row-field">
            <input
              type="password"
              name="menber_pass"
              value=""
              placeholder="**********"
            />
            <span className="oshc-error-note">Invalid password</span>
          </p>
          <div className="row-field">
            <p className="left">
              <input
                id="remenber_me_m"
                type="checkbox"
                name="remenber_me"
                value="1"
              />
              <label for="remenber_me_m">Remember me.</label>
            </p>
            <p className="right">
              <input type="submit" value="Login" name="login_submit" />
            </p>
          </div>
          <p className="row-field-forget">
            <a href="https://oshcstudents.com.au/login-member/?action=forget_pass">
              Forgot password?
            </a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default LoginFormModal;
