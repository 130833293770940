// sagas.js
import { call, put, takeEvery } from "redux-saga/effects";
import { API_URL, PROVIDER_ID } from "../constants/constant";
import {
  fetchProviderRequest,
  fetchProviderSuccess,
  fetchProviderFailure,
  fetchProvidersByUrlRequest,
  updateCommonQuoteDataFully,
  updateCommonQuoteDataByCover,
  saveCommonQuoteDataRequest,
  saveCommonQuoteDataSuccess,
  saveCommonQuoteDataFailure,
} from "../reducers/reducer-get-quote";
import { parseDateToString, getObjectToQueryParams } from "../utils/utils";
import ApiService from "../services/api/apiService";

export function* watchQuoteSaga() {
  yield takeEvery(fetchProviderRequest.type, fetchProviderSaga);
  yield takeEvery(fetchProvidersByUrlRequest.type, fetchProvidersByUrlSaga);
  yield takeEvery(saveCommonQuoteDataRequest.type, saveCommonQuoteDataSaga);
}

function* fetchProviderSaga(action) {
  try {
    let { providerId, quoteCommonData, agent } = action.payload;

    const path = API_URL.GET_PROVIDER;
    const options = {
      id: providerId,
      startDate: parseDateToString(quoteCommonData.startDate),
      endDate: parseDateToString(quoteCommonData.endDate),
      numberOfAdults: quoteCommonData.adultsNum,
      numberOfChildren: quoteCommonData.childrensNum,
      agentCode: agent || undefined,
    };
    const params = getObjectToQueryParams(options);
    const pathWithParams = options ? `${path}?${params}` : path;
    const data = yield call(() => ApiService.get(pathWithParams));

    if (data) {
      yield put(fetchProviderSuccess(data));
    }
  } catch (error) {
    yield put(fetchProviderFailure(error.message));
  }
}

function* fetchProvidersByUrlSaga(action) {
  try {
    if (action.payload.cover) {
      yield put(
        updateCommonQuoteDataByCover(getCoverCommonData(action.payload.cover))
      );
    } else {
      yield put(updateCommonQuoteDataFully(action.payload));
    }

    //get price for 5 providers
    for (const key in PROVIDER_ID) {
      var exist = action.payload.providerList.find(x => x.providerId === PROVIDER_ID[key]) != null;
      if (PROVIDER_ID.hasOwnProperty(key) && exist) {
        let request = {
          providerId: PROVIDER_ID[key],
        };
        yield put(fetchProviderRequest(request));
      }
    }
  } catch (error) {
    yield put(fetchProviderFailure(error.message));
  }
}

function* saveCommonQuoteDataSaga(action) {
  try {
    let apiUrl = API_URL.SEND_QUOTE_EMAIL;
    const response = yield call(() => ApiService.post(apiUrl, action.payload));
    if (response && response.status === 200) {
      yield put(saveCommonQuoteDataSuccess(response.data));
    }
  } catch (error) {
    yield put(saveCommonQuoteDataFailure(error.message));
  }
}

function getCoverCommonData(cover) {
  switch (cover) {
    case "single":
      return {
        adultsNum: 1,
        childrensNum: 0,
      };
    case "couple":
      return {
        adultsNum: 2,
        childrensNum: 0,
      };
    case "family":
      return {
        adultsNum: 2,
        childrensNum: 1,
      };
    case "single-parent":
      return {
        adultsNum: 1,
        childrensNum: 1,
      };
    default:
      return {
        adultsNum: 1,
        childrensNum: 0,
      };
  }
}
