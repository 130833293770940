import AllianzPayment from "./components/pages/AllianzPayment";
import NIBPayment from "./components/pages/NIBPayment";
import { PAGE_URL } from "./constants/constant";
import AlipayReturn from "./components/pages/AlipayReturn";
import WechatReturn from "./components/pages/WechatReturn";

const AppExtendRoutes = [
  {
    path: PAGE_URL.ALLIANZ_PAYMENT,
    element: <AllianzPayment />,
  },
  {
    path: PAGE_URL.NIB_PAYMENT,
    element: <NIBPayment />,
  },
  {
    path: PAGE_URL.ALIPAY_RETURN,
    element: <AlipayReturn />,
  },
  {
    path: PAGE_URL.WECHAT_RETURN,
    element: <WechatReturn />,
  },
];

export default AppExtendRoutes;
