import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "./CustomModal.css";

const CustomModal = (props) => {
  const {
    isShowing,
    toggle,
    header,
    content,
    size = "md",
    actionBtn = undefined,
    modalClassName = "",
    hiddenCancelBtn = false,
  } = props;
  return (
    <Modal
      modalClassName={modalClassName}
      isOpen={isShowing}
      toggle={toggle}
      size={size}
    >
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody className="customModal">{content}</ModalBody>
      {actionBtn && (
        <ModalFooter className="customModal">
          <div className="actionBlock">
            <Button
              className="actionBtn"
              color="primary"
              onClick={actionBtn?.action}
            >
              {actionBtn?.name}
            </Button>
            {!hiddenCancelBtn && (
              <Button className="cancelBtn" onClick={toggle}>
                Cancel
              </Button>
            )}
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default CustomModal;
