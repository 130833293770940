import React from "react";
import "./PaypalCheckout.css";
function PayPalCheckout(props) {
  const { url, surchargePercentage, totalAmount, surchargeAmount, amount } = props.paypalCheckout;

  const openCheckoutTab = () => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="paypal-container">
      <table>
        <tbody>
          <tr>
            <td>Premium:</td>
            <td>{amount.toFixed(2)} AUD </td>
          </tr>
          <tr>
            <td>Surcharge ({surchargePercentage}%):</td>
            <td>{surchargeAmount.toFixed(2)} AUD </td>
          </tr>
          <tr>
            <td>Total to pay:</td>
            <td>{totalAmount.toFixed(2)} AUD </td>
          </tr>
        </tbody>
      </table>
      <div className="paypal-checkout" onClick={openCheckoutTab}></div>
    </div>
  );
}

export default PayPalCheckout;
