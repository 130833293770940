import React, { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useGetAllianzCheckout from "../../hooks/useGetAllianzCheckout";
import useNationalities from "../../hooks/useNationalities";
import { Collapse } from "../Collapse";
import {
  GENDER_LIST,
  TITLE_LIST,
  DEFAULT_FORMAT_DATE,
  API_URL,
} from "../../constants/constant";
import moment from "moment";
import "./AllianzPayment.css";
import useModal from "../../hooks/useModal";
import PaymentSuccessModal from "../PaymentSuccessModal";
import PaymentErrorModal from "../PaymentErrorModal";
import Spinner from "../Spinner";
import useApiServices from "../../hooks/useApiServices";

const AllianzPayment = () => {
  const { id: quoteReference } = useParams();
  const [expanedIndex, setExpandedIndex] = useState(-1);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  const handleExpand = (index) => {
    setExpandedIndex((prev) => (prev === index ? -1 : index));
  };
  const { data: payment, isLoading: isLoadingPayment } = useGetAllianzCheckout({
    quoteReference,
  });
  const { data: nationalities } = useNationalities();

  const nationality = useMemo(
    () =>
      payment && nationalities
        ? nationalities?.find(
            (n) => n.code === payment?.personalDetail.nationality
          )?.name
        : "",
    [nationalities, payment]
  );
  const { postService } = useApiServices();

  const { isShowing, toggle } = useModal();
  const { isShowing: isErrorShowingModal, toggle: toggleErrorShowing } =
    useModal();

  useEffect(() => {
    const handler = (ev) => {
      if (typeof ev.data !== "object") return;

      if (quoteReference !== "") {
        if (
          ev.origin === "https://payment-page.emagin.eu" &&
          ev.data != null &&
          ev.data.paymentResponse != null
        ) {
          if (ev.data.paymentResponse.status === "Success") {
            setIsProcessingPayment(true);
            setTimeout(() => {
              postService(API_URL.TRANS_ALLIANZ_PAYMENT, {
                quoteReference: quoteReference,
              })
                .then(() => {
                  setIsProcessingPayment(false);
                  toggle();
                })
                .catch(() => {
                  setIsProcessingPayment(false);
                  toggleErrorShowing();
                });
            }, 30000);
          } else {
            toggleErrorShowing();
          }
        }
      }
    };

    window.addEventListener("message", handler);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler);
  }, [postService, quoteReference, toggle]);

  return (
    <div className="payment-url-container">
      <img
        className="logo"
        alt="Logo"
        src="https://oshcstudents.com.au/wp-content/themes/template_ecvn_v1.1/images/logo-small.png"
      ></img>
      <div className="allianz-payment">
        <h3 className="header">Allianz Payment</h3>
        <Collapse
          title="Personal Detail"
          isExpanded={expanedIndex === 0}
          onClick={() => handleExpand(0)}
        >
          <div className="personal-information">
            <div className="description">
              {payment?.personalDetail.title !== undefined && (
                <div className="item">
                  <span className="title">Title: </span>
                  <span className="value">
                    {
                      TITLE_LIST.find(
                        (t) => t.value === payment?.personalDetail.title
                      ).label
                    }
                  </span>
                </div>
              )}
              {payment?.personalDetail.firstName &&
                payment?.personalDetail.lastName && (
                  <div className="item">
                    <span className="title">Full Name: </span>
                    <span className="value">{`${payment?.personalDetail.firstName} ${payment?.personalDetail.lastName}`}</span>
                  </div>
                )}
              {payment?.personalDetail.gender !== undefined && (
                <div className="item">
                  <span className="title">Gender:</span>
                  <span className="value">
                    {
                      GENDER_LIST.find(
                        (t) => t.value === payment?.personalDetail.gender
                      ).label
                    }
                  </span>
                </div>
              )}
              {payment?.personalDetail.birthday && (
                <div className="item">
                  <span className="title">Birthday:</span>
                  <span className="value">
                    {moment(new Date(payment?.personalDetail.birthday)).format(
                      DEFAULT_FORMAT_DATE
                    )}
                  </span>
                </div>
              )}
              {payment?.personalDetail.passport && (
                <div className="item">
                  <span className="title">Passport:</span>
                  <span className="value">
                    {payment?.personalDetail.passport}
                  </span>
                </div>
              )}
              {payment?.personalDetail.nationality && (
                <div className="item">
                  <span className="title">Nationality:</span>
                  <span className="value">{nationality}</span>
                </div>
              )}
              {payment?.personalDetail.phone && (
                <div className="item">
                  <span className="title">Phone:</span>
                  <span className="value">{payment?.personalDetail.phone}</span>
                </div>
              )}
              {payment?.personalDetail.email && (
                <div className="item">
                  <span className="title">Email:</span>
                  <span className="value">{payment?.personalDetail.email}</span>
                </div>
              )}
            </div>
          </div>
        </Collapse>
        <Collapse
          title="Payment"
          isExpanded={expanedIndex === 1}
          onClick={() => handleExpand(1)}
        >
          <iframe src={payment?.paymentUrl} title="paymentUrl" />
        </Collapse>
        <PaymentSuccessModal isShowing={isShowing} toggle={toggle} />
        <PaymentErrorModal
          isShowing={isErrorShowingModal}
          toggle={toggleErrorShowing}
        />
        <Spinner
          text="Please wait, we are processing your invoice."
          isShowing={isLoadingPayment}
        />
        <Spinner
          text="Please wait, we are processing your payment."
          warningText="Please don't close this tab !"
          isShowing={isProcessingPayment}
        />
      </div>
    </div>
  );
};

export default AllianzPayment;
