import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "../../defaultCss/styles.css";
import "../../defaultCss/css-agent-plugin.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import {
  fetchProviderRequest,
  fetchProvidersByUrlRequest,
  updateCommonQuoteData,
  saveCommonQuoteDataRequest,
} from "../../reducers/reducer-get-quote";
import {
  PROVIDER_ID,
  API_FORMAT_DATE,
  DATE_PICKER_FORMAT_DATE,
  PAGE_URL,
} from "../../constants/constant";
import MaskedInput from "react-text-mask";
import useConfig from "../../hooks/useConfig";
import { getObjectToQueryParams } from "../../utils/utils";
import PaymentStepsBreadcrumb from "../PaymentStepsBreadcrumb";
import { PaymentStepsBreadcrumbItems } from "../../enums/enum";

const GetAQuote = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { data: dataConfig, isLoading: isLoadingConfig } = useConfig();
  const startDateInputRef = useRef(null);
  const endDateInputRef = useRef(null);

  const quoteCommonData = useSelector(
    (store) => store.getAQuoteReducer.quoteCommonData
  );
  const providerList = useSelector((store) => {
    return dataConfig && !isLoadingConfig
      ? store.getAQuoteReducer.providerList.filter(
          (p) => dataConfig[`enable${p.providerName}Provider`] === true
        )
      : [];
  });
  const savedQuote = useSelector((store) => store.getAQuoteReducer.savedQuote);

  const [toggle, setToggle] = useState(false);
  const [toggleBenefit, setToggleBenefit] = useState(false);
  const [email, setEmail] = useState("");

  const searchParams = useLocation().search;
  const startDateParam = new URLSearchParams(searchParams).get("startDate");
  const endDateParam = new URLSearchParams(searchParams).get("endDate");
  const adultsNumParam = new URLSearchParams(searchParams).get("adultsNum");
  const childrensNumParam = new URLSearchParams(searchParams).get(
    "childrensNum"
  );
  const coverParam = new URLSearchParams(searchParams).get("cover");

  const [searchParamsUrl] = useSearchParams();
  const agent = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentcode"),
    [searchParamsUrl]
  );
  const agentname = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentname"),
    [searchParamsUrl]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoadingConfig && dataConfig) {
      dispatch(
        fetchProvidersByUrlRequest({
          startDate: startDateParam,
          endDate: endDateParam,
          adultsNum: adultsNumParam || 1,
          childrensNum: childrensNumParam || 0,
          cover: coverParam,
          agent: agent,
          providerList: providerList,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerList.length]);

  const getProvider = useCallback(() => {
    //get price for 5 providers
    for (const key in PROVIDER_ID) {
      if (PROVIDER_ID.hasOwnProperty(key)) {
        let request = {
          quoteCommonData,
          providerId: PROVIDER_ID[key],
          agent: agent,
        };
        dispatch(fetchProviderRequest(request));
      }
    }
  }, [agent, dispatch, quoteCommonData]);

  const handleChangeCommonQuoteData = useCallback(
    (name, e) => {
      let updatedValue = e;
      if (name === "adultsNum" || name === "childrensNum") {
        updatedValue = parseInt(e.target.value);
      } else {
        updatedValue = moment(new Date(e));
      }
      dispatch(updateCommonQuoteData({ name, updatedValue }));
    },
    [dispatch]
  );

  const handleApplyQuote = useCallback(
    (provider) => {
      const path = PAGE_URL.OSHC_AU_APPLY_A_QUOTE;
      const options = {
        providerName: provider.providerName,
        startDate: quoteCommonData.startDate.format(API_FORMAT_DATE),
        endDate: quoteCommonData.endDate.format(API_FORMAT_DATE),
        adultsNum: quoteCommonData.adultsNum,
        childrensNum: quoteCommonData.childrensNum,
        agentcode: agent || undefined,
        agentname: agentname || undefined,
        cover: coverParam || undefined,
      };
      const params = getObjectToQueryParams(options);
      const pathWithParams = options ? `${path}?${params}` : path;
      navigate(pathWithParams);
    },
    [
      agent,
      coverParam,
      navigate,
      quoteCommonData.adultsNum,
      quoteCommonData.childrensNum,
      quoteCommonData.endDate,
      quoteCommonData.startDate,
    ]
  );

  const changeToggleBenefit = () => {
    setToggleBenefit(!toggleBenefit);
  };

  const changeToggle = () => {
    setToggle(!toggle);
  };

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  const openSaveQuotePopup = () => {
    setToggle(true);
    reset(email);
  };

  const openBenefitPopup = () => {
    setToggleBenefit(true);
  };

  const isLoading = providerList.find((x) => x.loading === true) != null;

  const onSubmit = () => {
    const request = {
      email: email,
      startDate: quoteCommonData.startDate.format(API_FORMAT_DATE),
      endDate: quoteCommonData.endDate.format(API_FORMAT_DATE),
      numberOfAdults: quoteCommonData.adultsNum,
      numberOfChildren: quoteCommonData.childrensNum,
    };
    dispatch(saveCommonQuoteDataRequest(request));
  };

  const CustomDateInput = forwardRef(({ value, onClick, onChange }, ref) => {
    const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
    return (
      <MaskedInput
        ref={ref}
        mask={dateMask}
        guide={false}
        placeholderChar={"\u2000"}
        value={value}
        onChange={onChange}
        onClick={onClick}
        disabled={isLoading}
      />
    );
  });

  return (
    <div className="page">
      <div id="template-oshc" className="uc">
        <div id="service-suport" className="spec">
          <h3 className="section-title">Register with our services</h3>
          <div id="wrap" className="template-oshc">
            <div className="fix-width">
              <PaymentStepsBreadcrumb
                activeField={PaymentStepsBreadcrumbItems.GetAQuote}
              />
              <div style={{ display: "none" }}>
                <form method="POST" action="">
                  <input name="invoice_test" value="test" type="submit" />
                </form>
              </div>
              <div
                id="divbenefit"
                style={{ position: "relative", width: "100%", height: "69px" }}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  id="a_benefit"
                  style={{ position: "absolute", right: "2px", top: "6px" }}
                >
                  <img
                    src="https://oshcstudents.com.au/wp-content/uploads/2016/01/compare_oshc_benefit.jpg"
                    data-src="https://oshcstudents.com.au/wp-content/uploads/2016/01/compare_oshc_benefit.jpg"
                    className="lazy"
                    style={{ width: "296px", cursor: "pointer" }}
                    alt="compare oshc benefit"
                    onClick={openBenefitPopup}
                  />
                </a>
              </div>
              <form action="" method="POST" className="main-quote">
                <div id="load"></div>
                <div id="form2">
                  <table className="imf" width="100%">
                    <tbody>
                      <tr className="th">
                        <td id="options_td">
                          <div style={{ marginBottom: "10px" }}>
                            Start Date <br />
                            <DatePicker
                              dateFormat={DATE_PICKER_FORMAT_DATE}
                              selected={new Date(quoteCommonData.startDate)}
                              onChange={(date) => {
                                handleChangeCommonQuoteData("startDate", date);
                                if (date >= new Date(quoteCommonData.endDate)) {
                                  handleChangeCommonQuoteData(
                                    "endDate",
                                    new Date(moment(date).add(1, "M"))
                                  );
                                }
                              }}
                              minDate={new Date(moment(new Date()))}
                              maxDate={new Date(moment(new Date()).add(5, "y"))}
                              customInput={
                                <CustomDateInput ref={startDateInputRef} />
                              }
                              name={"startDate"}
                            />
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            End Date <br />
                            <DatePicker
                              dateFormat={DATE_PICKER_FORMAT_DATE}
                              selected={new Date(quoteCommonData.endDate)}
                              onChange={(date) =>
                                handleChangeCommonQuoteData("endDate", date)
                              }
                              minDate={
                                new Date(
                                  moment(
                                    new Date(quoteCommonData.startDate)
                                  ).add(1, "d")
                                )
                              }
                              maxDate={
                                new Date(
                                  moment(
                                    new Date(quoteCommonData.startDate)
                                  ).add(8, "y")
                                )
                              }
                              customInput={
                                <CustomDateInput ref={endDateInputRef} />
                              }
                              name={"endDate"}
                              disabled={isLoading}
                            />
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            No of Adults <br />
                            <select
                              name="no_of_adults"
                              id="no_of_adults"
                              className="no_of_adults"
                              value={quoteCommonData.adultsNum}
                              onChange={(value) =>
                                handleChangeCommonQuoteData("adultsNum", value)
                              }
                              disabled={isLoading}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </select>
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            No of children <br />
                            <select
                              name="no_of_children"
                              id="no_of_children"
                              className="no_of_children"
                              value={quoteCommonData.childrensNum}
                              onChange={(value) =>
                                handleChangeCommonQuoteData(
                                  "childrensNum",
                                  value
                                )
                              }
                              disabled={isLoading}
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                          <p style={{ textAlign: "center", marginTop: "10px" }}>
                            <input
                              onClick={() => getProvider()}
                              className="btn_quote"
                              id="btn_quote"
                              type="button"
                              name="btn_quote"
                              value="Quote Now"
                              disabled={isLoading}
                              style={{
                                color: "#fff",
                                border: "none",
                                borderRadius: "13px",
                                width: "75%",
                                background: "#F54989",
                              }}
                            />
                            <input
                              id="btn_save"
                              type="button"
                              value="Save Quote for Later"
                              onClick={openSaveQuotePopup}
                              style={{
                                marginTop: "10px",
                                fontWeight: "normal",
                                color: "#fff",
                                border: "none",
                                borderRadius: "13px",
                                width: "75%",
                                background: "#F54989",
                              }}
                            />
                          </p>
                          <ul>
                            <li>
                              Your policy must start from the date you’ll arrive
                              in Australia, or the date that any previous policy
                              ends (or is cancelled).
                            </li>
                            <li>
                              Your policy must cover until at least the end of
                              your student visa.
                            </li>
                            <li>
                              <a
                                style={{ color: "#f54989" }}
                                href="https://oshcstudents.com.au/how-to-calculate-oshc-length/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                How to calculate the start date and the end date
                                of visa(?)
                              </a>
                            </li>
                          </ul>
                        </td>
                        <td id="provider_td">
                          <table>
                            <tbody>
                              <tr>
                                {providerList.map(function (provider, index) {
                                  return (
                                    <td
                                      className="text_center uc_768 uc_oshc"
                                      key={index}
                                    >
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <img
                                                className="lazy img_logo img_display"
                                                src={provider.logoUrl}
                                                data-src={provider.logoUrl}
                                                alt="ahm"
                                              />
                                            </td>
                                            <td>
                                              <a
                                                href={provider.oshcDocumentUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="a_policy"
                                              >
                                                OSHC Policy Document
                                              </a>
                                              <br />
                                              <a
                                                href={provider.oshcCardUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="a_card"
                                              >
                                                Activate your OSHC card
                                              </a>
                                              <br />
                                              <a
                                                href={provider.oshcGuidelineUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="a_guideline"
                                              >
                                                OSHC Guideline
                                              </a>
                                            </td>
                                            <td
                                              id={provider.priceContainerId}
                                              className="price-item"
                                            >
                                              {provider.loading ? (
                                                <img
                                                  id={
                                                    provider.priceLoadingImgId
                                                  }
                                                  className="lazy loading_oshc"
                                                  src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Loading_icon_cropped.gif"
                                                  data-src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Loading_icon_cropped.gif"
                                                  style={{
                                                    height: "20px",
                                                    marginTop: "20px",
                                                  }}
                                                  alt="loading icon cropped"
                                                />
                                              ) : (
                                                ""
                                              )}
                                              {!provider.loading ? (
                                                <span
                                                  id={provider.priceTagId}
                                                  className={
                                                    provider.priceTagClass +
                                                    (provider.price !== 0
                                                      ? " price_text_qa"
                                                      : " price_text_qa_none")
                                                  }
                                                >
                                                  {provider.price !== 0
                                                    ? `$ ${provider.price.toFixed(
                                                        2
                                                      )}`
                                                    : "-- None --"}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td>
                                              <div className="button1">
                                                {!provider.loading
                                                  ? provider.price !== 0 && (
                                                      <input
                                                        onClick={() =>
                                                          handleApplyQuote(
                                                            provider
                                                          )
                                                        }
                                                        className={
                                                          provider.btnSubmitClass +
                                                          " btn"
                                                        }
                                                        id={provider.btn_ahm}
                                                        type="button"
                                                        name="submit_get_a_quote"
                                                        value="Buy now"
                                                      />
                                                    )
                                                  : ""}
                                                <input
                                                  className="btn_quote btn"
                                                  id={provider.btn_quote_ahm}
                                                  type="hidden"
                                                  value="Quote Now"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                          <p>
                            *Also note: if you hold a student dependent visa,
                            you must be insured under the same policy as the
                            main student visa holder. You are only eligible to
                            hold a single OSHC policy if you are the primary
                            visa holder
                          </p>
                          <p>{dataConfig?.bannerHtml}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={toggle} toggle={changeToggle} size="md">
        <ModalHeader>Email Quote</ModalHeader>
        <ModalBody style={{ padding: "3rem" }}>
          <p>
            Enter your email to have this quote saved for you to come back to
            any time.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input
                type="email"
                name="email"
                size="40"
                className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                id="savequote_email"
                value={email}
                {...register("email", {
                  required: "This field is required.",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Entered value does not match email format",
                  },
                })}
                onChange={changeEmail}
                aria-required="true"
                aria-invalid="false"
                placeholder="Email"
              />
              <input
                type="submit"
                value="Email Quote"
                className="wpcf7-form-control wpcf7-submit"
                id="savequote_submit"
              ></input>
              <div>
                {errors.email && (
                  <span className="err-item">{errors.email.message}</span>
                )}
              </div>
            </div>
            {savedQuote && (
              <div
                className="wpcf7-response-output"
                role="alert"
                aria-hidden="true"
              >
                Thank you for your message. It has been sent.
              </div>
            )}
          </form>
        </ModalBody>
      </Modal>

      <Modal isOpen={toggleBenefit} toggle={changeToggleBenefit} size="xl">
        <ModalHeader>Compare OSHC benefits</ModalHeader>
        <ModalBody style={{ padding: "3rem" }}>
          <img
            src="https://oshcstudents.com.au/wp-content/uploads/2016/01/OSHC-benefit-compare-2022.png"
            data-src=""
            className="lazy"
            style={{ maxWidth: "100%" }}
            alt=""
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default GetAQuote;
