import React, { Component } from "react";
import MobileMenu from "../layout/MobileMenu";
import "../../defaultCss/styles.css";

export default class SearchBar extends Component {
  render() {
    return (
      <>
        <div className="banner fix-width on-desktop">
          <a
            href="https://oshcstudents.com.au/"
            className="logo-img logo_mobile"
          >
            <img
              className="lazy"
              // src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              src="https://oshcstudents.com.au/wp-content/themes/template_ecvn_v1.1/images/logo/top-banner.png"
              alt="OSHC STUDENTS"
            />
          </a>
          <div className="site-information">
            <div className="search">
              <form
                className="search-form"
                action="https://oshcstudents.com.au/"
              >
                <input type="text" name="s" placeholder="Keywords ..." />
                <button type="submit" value="fav_HTML"></button>
              </form>
            </div>
          </div>
          <span className="menu-mobile">
            <i className="fa fa-bars"></i>
          </span>
        </div>
        <MobileMenu />
      </>
    );
  }
}
