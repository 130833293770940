import React, { useState } from "react";
import { API_URL, VALID_FILE_EXTENSION } from "../constants/constant";
import { getFileExtension } from "../utils/utils";
import ApiService from "../services/api/apiService";
import "./Upload.css";

const Upload = React.forwardRef((props, ref) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setErrror] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // ref
  const inputRef = React.useRef(null);

  const handleFile = (files) => {
    if (uploadedFiles.length + files.length > 10) {
      setErrror(true);
      return;
    }

    setUploading(true);
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      var extension = getFileExtension(files[i].name);
      if (
        files[i].size > 5242880 ||
        !VALID_FILE_EXTENSION.includes(extension)
      ) {
        setErrror(true);
        setUploading(false);
        return;
      }
      formData.append("files", files[i]);
    }

    formData.append("type", props.type);

    setErrror(false);
    ApiService.post(API_URL.STORAGE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((data) => {
      setUploading(false);
      let u_files = [];
      for (let i = 0; i < files.length; i++) {
        u_files.push({
          name: files[i].name,
          type: files[i].type,
          newFileName: data[files[i].name],
        });
      }

      const draft = [...uploadedFiles, ...u_files];
      setUploadedFiles(draft);
      inputRef.current.value = "";
      props.filesChanged(draft);
    });
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onDeleteClick = (fileName) => {
    ApiService.post(API_URL.DELETE_FILE, {
      documentName: fileName,
      type: props.type,
    }).then((_) => {
      const draft = uploadedFiles.filter(
        (element) => element.newFileName !== fileName
      );
      setUploadedFiles(draft);
      props.filesChanged(draft);
    });
  };

  return (
    <div>
      {uploading && (
        <div className="uploading-container">
          <span>Uploading...</span>
          <img
            className="lazy loading_oshc"
            src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Loading_icon_cropped.gif"
            data-src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Loading_icon_cropped.gif"
            style={{
              height: "20px",
            }}
            alt="loading icon cropped"
          />
        </div>
      )}
      <div id="form-file-upload" onDragEnter={handleDrag}>
        <input
          ref={inputRef}
          type="file"
          id={props.inputId}
          multiple={true}
          accept="image/*,.pdf"
          onChange={handleChange}
        />
        <label
          id="label-file-upload"
          htmlFor={props.inputId}
          className={dragActive ? "drag-active" : ""}
        >
          <div className="upload-container">
            <span>Drag and drop your file here</span>
            <span>Max: 5MB/1 File - 10 Files</span>
            <button
              type="button"
              className="upload-button"
              onClick={onButtonClick}
            >
              Upload files
            </button>
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
      {error && (
        <div className="upload-error-container">
          <span className="err-item">
            Only select PDF, JPEG, JPG, PNG, BMP, WEBP, TIF, TIFF, SVG, ICO, HEIF, HEIC file and &lt; 5MB/1 File
            - 10 Files.
          </span>
        </div>
      )}
      {uploadedFiles != null && uploadedFiles.length > 0 && (
        <div className="uploaded-container">
          {uploadedFiles.map((file) => (
            <div key={file.newFileName} className="file-item">
              <i className="fa fa-file-o"></i>
              <div className="file-item-name">{file.name}</div>
              <div className="file-item-type">{file.type}</div>
              <i
                className="fa fa-close"
                onClick={() => onDeleteClick(file.newFileName)}
              ></i>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default Upload;
