import React, { useCallback, useState, useMemo } from "react";
import { Collapse } from "../Collapse";
import LoginFormModal from "../LoginFormModal";
import useModal from "../../hooks/useModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { navigateToStudentInsurance } from "../../utils/utils";
import "../../defaultCss/styles.css";
import "./MobileMenu.css";

const MobileMenu = () => {
  const navigate = useNavigate();
  const [expanedIndex, setExpandedIndex] = useState(-1);
  const [expanedSubIndex, setExpandedSubIndex] = useState(-1);
  const { isShowing: isShowingLogin, toggle: toggleShowingLogin } = useModal();
  const { isShowing: isShowingDropMenu, toggle: toggleShowingDropMenu } =
    useModal();

  const [searchParamsUrl] = useSearchParams();
  const agent = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentcode"),
    [searchParamsUrl]
  );
  const agentname = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentname"),
    [searchParamsUrl]
  );
  const handleExpand = useCallback((index) => {
    setExpandedIndex((prev) => (prev === index ? -1 : index));
  }, []);

  const handleSubExpand = useCallback((index) => {
    setExpandedSubIndex((prev) => (prev === index ? -1 : index));
  }, []);

  const navigateToStudentInsurancePage = () => {
    navigateToStudentInsurance(navigate, agent, agentname);
  };

  return (
    <div className="on-mobile mobile-menu">
      <a href="https://oshcstudents.com.au/">
        <img
          className="lazy logo"
          // src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          src="https://oshcstudents.com.au/wp-content/themes/template_ecvn_v1.1/images/logo/top-banner.png"
          alt="OSHC STUDENTS"
          loading="lazy"
        />
      </a>
      <form className="search-form" action="https://oshcstudents.com.au/">
        <input type="text" name="s" className="search-input" />
        <i className="fa fa-search search-icon"></i>
      </form>
      <span className="menu-icon" onClick={toggleShowingDropMenu}>
        <i
          className={`icon-menu fa ${
            isShowingDropMenu ? "fa-times" : "fa-bars"
          } `}
        ></i>
      </span>
      <hr style={{ margin: 0 }} />
      {!isShowingDropMenu && (
        <div id="bottom-header-mobile">
          <div id="menu-menu-login-m">
            {/* <span
              id="span_login"
              className="menu-button"
              onClick={toggleShowingLogin}
            >
              Login
            </span> */}
            {isShowingLogin && <LoginFormModal isShowing={isShowingLogin} />}
            <a
              href="https://oshcstudents.com.au/contact-us/"
              className="contact"
            >
              <span>Contact us</span>
            </a>
            {!agentname && (
              <a
                href="https://oshcstudents.com.au/apply-to-join-us/"
                className="m-menu-right"
              >
                <span>Become an Agent</span>
              </a>
            )}

            {agentname && (
              <a
                href="https://oshcstudents.com.au/member-account/?action=dashboard"
                className="m-menu-right m-agent-name-btn"
              >
                <span>{agentname.substring(0, 12)}</span>
              </a>
            )}
          </div>
        </div>
      )}
      {isShowingDropMenu && (
        <div id="drop-menu">
          <Collapse
            title="Services"
            isExpanded={expanedIndex === 1}
            onClick={() => handleExpand(1)}
            className="menu-item-61462"
          >
            <Collapse
              title="Australia"
              isExpanded={expanedSubIndex === 11}
              onClick={() => handleSubExpand(11)}
              className="menu-item-61463"
            >
              <span
                className="payment-item menu-item-61464"
                onClick={navigateToStudentInsurancePage}
              >
                OSHC Student insurance
              </span>
              <a
                className="payment-item menu-item-61471"
                href="https://oshcstudents.com.au/visitor-and-work-insurance-australia/"
              >
                OVHC Visitor insurance
              </a>
              <a
                className="payment-item menu-item-61473"
                href="https://oshcstudents.com.au/?post_type=service&p=83"
              >
                Accommodation
              </a>
              <a
                className="payment-item menu-item-61475"
                href="https://oshcstudents.com.au/?post_type=service&p=82"
              >
                Airport pickup
              </a>
            </Collapse>
            <Collapse
              title="Airport pickup"
              isExpanded={expanedSubIndex === 12}
              onClick={() => handleSubExpand(12)}
              className="menu-item-61484"
            ></Collapse>
            <Collapse
              title="USA & Other countries"
              isExpanded={expanedSubIndex === 13}
              onClick={() => handleSubExpand(13)}
              className="menu-item-61485"
            >
              <a
                className="menu-item-61488"
                href="https://oshcstudents.com.au/student-insurance-usa/"
              >
                Student insurance
              </a>
              <a
                className="menu-item-61489"
                href="https://oshcstudents.com.au/visitor-and-work-insurance-usa/"
              >
                Visitor & work insurance
              </a>
              <a
                className="menu-item-61491"
                href="https://oshcstudents.com.au/?post_type=service&p=434"
              >
                Accommodation
              </a>
              <a
                className="menu-item-61493"
                href="https://oshcstudents.com.au/?post_type=service&p=581"
              >
                Airport pickup
              </a>
            </Collapse>
          </Collapse>
          <Collapse
            title="Flywire"
            isExpanded={expanedIndex === 2}
            onClick={() => {
              window.location.href =
                "https://oshcstudents.com.au/other-services/flywire/";
            }}
            className="menu-item-175665"
          ></Collapse>
          <Collapse
            title="Study Tour"
            isExpanded={expanedIndex === 4}
            onClick={() => {
              handleExpand(4);
            }}
            className="menu-item-61495"
          >
            <a
              className="menu-item-61496"
              href="https://oshcstudents.com.au/study-tour-australia/"
            >
              Study Tour Australia
            </a>
            <a
              className="menu-item-61497"
              href="https://oshcstudents.com.au/study-tour-united-kingdom/"
            >
              Study Tour United Kingdom
            </a>
          </Collapse>
          <Collapse
            title="Other Services"
            isExpanded={expanedIndex === 5}
            onClick={() => {
              handleExpand(5);
            }}
            className="menu-item-61498"
          >
            <a
              className="menu-item-61499"
              href="https://oshcstudents.com.au/pte-platform/"
            >
              PTE Platform
            </a>
            <a
              className="menu-item-61500"
              href="https://oshcstudents.com.au/marketing-solutions/"
            >
              Marketing solutions
            </a>
            <a
              className="menu-item-61502"
              href="https://oshcstudents.com.au/design-branding-website/"
            >
              Design – Branding & Website
            </a>
            <a
              className="menu-item-61503"
              href="https://oshcstudents.com.au/other-services/education-agent-crm/"
            >
              Education Agent CRM
            </a>
          </Collapse>
          <Collapse
            title="Special Deals"
            isExpanded={expanedIndex === 6}
            onClick={() => {
              window.location.href =
                "https://oshcstudents.com.au/category/special-deals/";
            }}
            className="menu-item-61504"
          ></Collapse>
          <Collapse
            title="News"
            isExpanded={expanedIndex === 7}
            onClick={() => {
              handleExpand(7);
            }}
            className="menu-item-61505"
          >
            <a
              className="menu-item-61506"
              href="https://oshcstudents.com.au/category/news/study-tour-news/"
            >
              Study Tour News
            </a>
            <a
              className="menu-item-61507"
              href="https://oshcstudents.com.au/category/news/oshc-ovhc-support/"
            >
              OSHC & OVHC support
            </a>
            <a
              className="menu-item-61508"
              href="https://oshcstudents.com.au/category/news/flywire/"
            >
              Flywire
            </a>
            <a
              className="menu-item-61509"
              href="https://oshcstudents.com.au/category/news/living-studying-abroad/"
            >
              Living & Studying abroad
            </a>
          </Collapse>
          <Collapse
            title="FAQS"
            isExpanded={expanedIndex === 6}
            onClick={() => {
              window.location.href =
                "https://oshcstudents.com.au/oshc-frequently-asked-questions/";
            }}
            className="menu-item-61510"
          ></Collapse>
        </div>
      )}
    </div>
  );
};
export default MobileMenu;
