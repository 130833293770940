import { createSlice, current } from "@reduxjs/toolkit";
import produce from "immer";
import { Relationship } from "../enums/enum";
import _set from "lodash.set";
import moment from "moment";
import { createPerson, getAge } from "../utils/utils";
import { DEFAULT_APPLY_DATA, PROVIDER_ID } from "../constants/constant";

const initialState = {
  loading: false,
  error: null,
  data: DEFAULT_APPLY_DATA,
  isProcessing: false,
  visaMastercardCheckout: {
    token: "",
    url: "",
  },
  paypalCheckout: {
    url: "",
    surchargePercentage: 0,
    surchargeAmount: 0,
    totalAmount: 0,
    amount: 0
  },
  invoiceNumber: "",
  policyNumber: "",
  quoteReference: "",
  bankTransfers: [],
  nepalBankTransfers: [],
  agent: "",
  enablePayment: {},
  alipayCheckout: {},
  student: {},
  invalidDate: false
};

const applyQuoteSlice = createSlice({
  name: "applyQuote",
  initialState,
  reducers: {
    initialData(state, action) {
      const data = action.payload.isBackToTransaction
        ? current(state).data
        : initialState.data;

      const updatedData = produce(data, (draftData) => {
        draftData.providerId = action.payload.providerId;

        if (action.payload.providerId === PROVIDER_ID.ALLIANZ) {
          _set(draftData, "hasAustralianAddress", false);
        }

        draftData.startDate = moment(
          new Date(action.payload.quoteCommonData.startDate)
        );
        draftData.endDate = moment(
          new Date(action.payload.quoteCommonData.endDate)
        );
        draftData.numberOfAdults = parseInt(
          action.payload.quoteCommonData.adultsNum
        );
        draftData.numberOfChildren = parseInt(
          action.payload.quoteCommonData.childrensNum
        );
        draftData.partner = new Array(draftData.numberOfAdults - 1).fill(
          createPerson(Relationship.Partner)
        );
        draftData.children = new Array(draftData.numberOfChildren).fill(
          createPerson(Relationship.Children)
        );
      });
      state.data = updatedData;
      state.agent = action.payload.agent;
    },
    updateData(state, action) {
      const data = current(state).data;

      const { propertyName, value } = action.payload;
      let updatedData = produce(data, (draftData) => {
        let parsedValue = value;
        _set(draftData, propertyName, parsedValue);
      });

      if (
        propertyName === "paymentCountry" ||
        propertyName === "birthday.day" ||
        propertyName === "birthday.month" ||
        propertyName === "birthday.year"
      ) {
        updatedData = produce(updatedData, (draftData) => {
          var uploadRequires = visibilityUpload(updatedData);

          draftData.uploadRequires = [...uploadRequires];
          draftData.expanedIndexs = [...uploadRequires];
        });
      }

      state.data = updatedData;
    },
    applyRequest(state, action) {
      state.isProcessing = true;
      state.visaMastercardCheckout = {
        token: "",
        url: "",
      };
      state.paypalCheckout = {
        url: "",
        surchargePercentage: 0,
        surchargeAmount: 0,
        totalAmount: 0,
        amount: 0,
      };
      state.invoiceNumber = "";
      state.policyNumber = "";
      state.quoteReference = "";
      state.bankTransfers = [];
      state.nepalBankTransfers = [];
      state.enablePayment = {};
      state.alipayCheckout = {};
      state.wechatCheckout = {};
      state.student = {};
      state.error = null;
      state.invalidDate = false;
    },
    getAgentRequest(state, action) {},
    getAgentSuccess(state, action) {
      state.data.agentCode = action.payload.agentCode;
      state.data.educationAgent = action.payload.agentName;
    },
    backToTransaction(state, action) {
      state.isProcessing = true;
      state.visaMastercardCheckout = {
        token: "",
        url: "",
      };
      state.paypalCheckout = {
        url: "",
        surchargePercentage: 0,
        surchargeAmount: 0,
        totalAmount: 0,
        amount: 0,
      };
      state.invoiceNumber = "";
      state.policyNumber = "";
      state.quoteReference = "";
      state.bankTransfers = [];
      state.nepalBankTransfers = [];
      state.enablePayment = {};
      state.alipayCheckout = {};
      state.wechatCheckout = {};
      state.student = {};
      state.invalidDate = false;
    },
    applySuccess(state, action) {
      state.isProcessing = false;
      state.data = mapDataToState(action.payload.student);
      state.visaMastercardCheckout = action.payload.visaMastercardCheckout;
      state.paypalCheckout = action.payload.paypalCheckout;
      state.invoiceNumber = action.payload.invoiceNumber;
      state.quoteReference = action.payload.quoteReference;
      state.policyNumber = action.payload.policyNumber;
      state.bankTransfers = action.payload.bankTransfers;
      state.nepalBankTransfers = action.payload.nepalBankTransfers;
      state.enablePayment = action.payload.enablePayment;
      state.alipayCheckout = action.payload.alipayCheckout;
      state.wechatCheckout = action.payload.wechatCheckout;
      state.student = action.payload.student;
      state.invalidDate = action.payload.invalidDate;
    },
    applyFailure(state, action) {
      state.isProcessing = false;
      state.error = action.payload;
    },
  },
});

function visibilityUpload(data) {
  var birthday = new Date(
    `${data.birthday.year}-${data.birthday.month
      .toString()
      .padStart(2, "0")}-${data.birthday.day.toString().padStart(2, "0")}`
  );

  var age = getAge(birthday);
  var uploadRequiresTemp = [false, false, false, false, false];

  if (data.paymentCountry === "VNM") {
    if (age >= 18) {
      uploadRequiresTemp = [true, true, false, false, false];
    } else {
      uploadRequiresTemp = [true, true, true, false, true];
    }
  } else if (data.paymentCountry === "PAK") {
    uploadRequiresTemp = [false, true, false, false, false];
  }

  return [...uploadRequiresTemp];
}

function mapDataToState(data) {
  var birthday = new Date(data.birthday);
  let current = {};
  current.id = data.id;
  current.providerId = data.providerId;
  current.startDate = moment(new Date(data.startDate));
  current.endDate = moment(new Date(data.endDate));
  current.numberOfAdults = data.numberOfAdults;
  current.numberOfChildren = data.numberOfChildren;
  current.title = data.title;
  current.firstName = data.firstName;
  current.lastName = data.lastName;
  current.gender = data.gender;
  current.birthday = {
    day: birthday.getDate(),
    month: birthday.getMonth() + 1,
    year: birthday.getFullYear(),
  };
  current.passport = data.passport;
  current.nationality = data.nationality;
  current.institution = data.institution;
  current.studentId = data.studentId;
  current.existingMember = data.existingMember;
  current.policyNumber = data.policyNumber;
  current.streetAndress = data.streetAndress;
  current.city = data.city;
  current.state = data.state;
  current.postCode = data.postCode;
  current.phone = data.phone;
  current.email = data.email;
  current.emailConfirmation = data.email;
  current.emailReceiveInvoice = data.emailReceiveInvoice;
  current.educationAgent = data.educationAgent;
  current.hasAustralianAddress = data.hasAustralianAddress;
  current.agentCode = data.agentCode;
  current.paymentCountry = data.paymentCountry;
  current.partner = [];
  current.children = [];

  if (data.persons != null) {
    data.persons.forEach((item) => {
      var personBirthday = new Date(item.birthday);
      var person = {
        ...item,
        birthday: {
          day: personBirthday.getDate(),
          month: personBirthday.getMonth() + 1,
          year: personBirthday.getFullYear(),
        },
      };

      if (item.relationship === Relationship.Partner) {
        current.children.push(person);
      } else {
        current.partner.push(person);
      }
    });
  }
  // Reset documents
  current.passportFiles = [];
  current.offerLetterOrCOEFiles = [];
  current.birthCertificateFiles = [];
  current.coiFiles = [];
  current.identificationOfMotherOrFatherFiles = [];

  const updatedData = produce(current, (draftData) => {
    var uploadRequires = visibilityUpload(current);

    draftData.uploadRequires = [...uploadRequires];
    draftData.expanedIndexs = [...uploadRequires];
  });

  current = updatedData;

  return current;
}

export const {
  initialData,
  updateData,
  applyRequest,
  applySuccess,
  applyFailure,
  backToTransaction,
  getAgentRequest,
  getAgentSuccess,
} = applyQuoteSlice.actions;

export default applyQuoteSlice.reducer;
