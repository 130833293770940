import React, { Component } from 'react';
import "../../defaultCss/styles.css";

export default class Header extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="fix-width information-company">
          <div className="list-offices">
            <div className="office">
              <ul className="info">
                <li className="address">Annalink OSHCstudents</li>
                <li className="address">
                  19/20-22 Anglo Rd, Campsie, NSW 2194, Australia
                </li>
                <li className="tel">ABN: 26 609 001 185</li>
              </ul>
            </div>
            {/* <div
              className="fb-page"
              data-href="https://www.facebook.com/OSHCstudents.annalink/"
              data-tabs="timeline"
              data-width="280"
              data-height="100"
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="false"
            >
              <blockquote
                cite="https://www.facebook.com/OSHCstudents.annalink/"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/OSHCstudents.annalink/">
                  OSHCstudents
                </a>
              </blockquote>
            </div> */}
          </div>
          <div className="about">
            <h3 className="title_footer">About Annalink OSHCstudents</h3>
            <div className="content">
              <div className="video">
                <div
                  className="ht-video-show-thumb widget clear"
                  style={{ display: "none" }}
                >
                  <img
                    className="lazy"
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    data-src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Rectangle-39.png"
                    alt="video"
                    style={{ display: "block" }}
                  />
                  <div
                    className="iframe"
                    style={{ height: "100%", display: "none" }}
                  ></div>
                </div>
              </div>
              <div className="text">
                <p>
                  <span style={{ color: "#f54989" }}>
                    <strong>Annalink OSHCstudents</strong>
                  </span>
                  is an internationally recognized network offering a wide range
                  of international student and visitor services. Our services
                  are always in great demands for people who will go overseas as
                  a visitor or a student. The range of services includes but not
                  limited to insurance, tuition fee transfer – Flywire, study
                  tour, PTE platform, mobile SIM cards, and banking.
                </p>
                <a
                  className="more"
                  href="https://oshcstudents.com.au/about-oshcstudents/"
                >
                  Read more
                </a>
                <a
                  className="policy"
                  href="https://oshcstudents.com.au/privacy-policy"
                >
                  Privacy policy
                </a>
                <a
                  className="terms"
                  href="https://oshcstudents.com.au/terms-and-conditons"
                >
                  Terms and conditions
                </a>
                <a
                  className="refund"
                  href="https://oshcstudents.com.au/refunds-policy/"
                >
                  REFUND POLICY
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix bar-footer">
          <div className="fix-width">
            <div className="copyright ">
              <p>
                Copyright 2015 © Annalink OSHCstudents. All rights reserved. ABN
                26 609 001 185.
              </p>
            </div>
            <div className="copyright right "></div>
          </div>
        </div>
      </footer>
    );
  }
}
