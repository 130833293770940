import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./PaypalReturn.css";
import ApiService from "../../services/api/apiService";
import { API_URL } from "../../constants/constant";
import useApiServices from "../../hooks/useApiServices";

const STATUS = {
  SUCCESS: "success",
  FAIL: "fail",
  CANCEL: "cancel",
};

const TEXT = {
  success: "Your payment was successful.",
  fail: "Your payment was failed",
  cancel: "You cancelled. You can try later",
};

const PaypalReturn = (props) => {
  const { status = null } = props;
  const [internalStatus, setInternalStatus] = useState(status);

  const params = useLocation().search;
  const { postService } = useApiServices();

  useEffect(() => {
    if (status == null) {
      const paymentId = new URLSearchParams(params).get("paymentId");
      const token = new URLSearchParams(params).get("token");
      const payerId = new URLSearchParams(params).get("PayerID");

      postService(API_URL.TRANS_PAYPAL_RETURN, {
        paymentId: paymentId,
        token: token,
        payerId: payerId,
      })
        .then(() => {
          setInternalStatus(STATUS.SUCCESS);
        })
        .catch(() => {
          setInternalStatus(STATUS.FAIL);
        });
    }
  }, []);

  return (
    internalStatus != null && (
      <div className="paypal-return">
        <img
          src={`icon-${internalStatus}.png`}
          alt={`icon ${internalStatus}`}
        />
        <p>{TEXT[internalStatus]}</p>
        <img
          className="logo"
          alt="Paypal"
          src="https://d10bqim15li85m.cloudfront.net/Upload/Paypal.svg"
        ></img>
      </div>
    )
  );
};

export default PaypalReturn;
