import { useCallback, useEffect, useState } from "react";
import { API_URL } from "../constants/constant";
import useApiServices from "./useApiServices";

const useGetAllianzCheckout = (props) => {
  const { quoteReference } = props;
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getService } = useApiServices();

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    getService(API_URL.TRANS_GET_ALLIANZ_CHECKOUT, {
      quoteReference,
    })
      .then((data) => {
        setIsLoading(false);
        setData(data);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });
  }, [getService, quoteReference]);

  useEffect(() => {
    fetchData();
  }, []);

  return {
    data,
    isLoading,
    error,
  };
};

export default useGetAllianzCheckout;
