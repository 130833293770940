import { createSlice, current } from "@reduxjs/toolkit";
import produce from "immer";
import moment from "moment-timezone";
import { PROVIDER_LIST } from "../constants/constant";

const initialState = {
  providerList: PROVIDER_LIST,
  loading: false,
  error: null,
  quoteCommonData: {
    startDate: moment(new Date()),
    endDate: moment(new Date()).add(1, "M"),
    adultsNum: 1,
    childrensNum: 0,
  },
  savedQuote: false,
  agent: "",
};

const getQuoteSlice = createSlice({
  name: "getQuote",
  initialState,
  reducers: {
    fetchProviderRequest(state, action) {
      const providerId = action.payload.providerId;
      const { providerList, quoteCommonData, agent } = current(state);

      if (!action.payload.quoteCommonData) {
        action.payload.quoteCommonData = quoteCommonData;
      }

      action.payload.agent = agent;

      const requestProviderIndex = providerList.findIndex(
        (provider) => provider.providerId === providerId
      );
      const updatedProviderList = produce(providerList, (draftList) => {
        const requestProvider = draftList[requestProviderIndex];
        requestProvider.loading = true;
      });

      state.providerList = updatedProviderList;
    },
    fetchProviderSuccess(state, action) {
      const providerId = action.payload.id;
      const providerList = current(state).providerList;

      const updatedProviderList = produce(providerList, (draftList) => {
        const responseProvider = draftList.find(
          (provider) => provider.providerId === providerId
        );
        responseProvider.price = action.payload.price;
        responseProvider.name = action.payload.name;
        responseProvider.logoUrl = action.payload.logo;
        responseProvider.loading = false;
        draftList.sort((a, b) => a.sort - b.sort);
      });
      state.providerList = updatedProviderList;
    },
    fetchProviderFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchProvidersByUrlRequest(state, action) {
      state.agent = action.payload.agent;
    },
    updateCommonQuoteDataFully(state, action) {
      const quoteCommonData = current(state).quoteCommonData;
      const updatedQuoteCommonData = produce(quoteCommonData, (draftData) => {
        if (action.payload.startDate != null) {
          draftData.startDate = moment(new Date(action.payload.startDate));
        }
        if (action.payload.endDate != null) {
          draftData.endDate = moment(new Date(action.payload.endDate));
        }
        draftData.adultsNum = action.payload.adultsNum;
        draftData.childrensNum = action.payload.childrensNum;
      });
      state.quoteCommonData = updatedQuoteCommonData;
    },
    updateCommonQuoteData(state, action) {
      const { name, updatedValue } = action.payload;

      const providerList = current(state).providerList;

      const updatedProviderList = produce(providerList, (draftList) => {
        draftList.forEach((item) => (item.loading = false));
      });

      state.quoteCommonData[name] = updatedValue;
      state.providerList = updatedProviderList;
    },
    updateCommonQuoteDataByCover(state, action) {
      const quoteCommonData = current(state).quoteCommonData;
      const updatedQuoteCommonData = produce(quoteCommonData, (draftData) => {
        draftData.adultsNum = action.payload.adultsNum;
        draftData.childrensNum = action.payload.childrensNum;
      });

      const providerList = current(state).providerList;

      const updatedProviderList = produce(providerList, (draftList) => {
        draftList.forEach((item) => (item.loading = true));
      });

      state.quoteCommonData = updatedQuoteCommonData;
      state.providerList = updatedProviderList;
    },
    saveCommonQuoteDataRequest(state, action) {
      state.savedQuote = false;
    },
    saveCommonQuoteDataSuccess(state, action) {
      state.savedQuote = true;
    },
    saveCommonQuoteDataFailure(state, action) {
      state.savedQuote = false;
    },
  },
});

export const {
  fetchProviderRequest,
  fetchProviderSuccess,
  fetchProviderFailure,
  fetchProvidersByUrlRequest,
  updateCommonQuoteDataFully,
  updateCommonQuoteData,
  updateCommonQuoteDataByCover,
  saveCommonQuoteDataRequest,
  saveCommonQuoteDataSuccess,
  saveCommonQuoteDataFailure,
} = getQuoteSlice.actions;

export default getQuoteSlice.reducer;
