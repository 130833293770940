import React, { useCallback, useState } from "react";
import "./BankTransferCheckout.css";
import TelegraphicTransfer from "./TelegraphicTransfer";

function BankTransferCheckout(props) {
  const { bankTransfers, nationality, invoiceNumber } = props;
  const [bankSelected, setBankSelected] = useState(bankTransfers[0]);

  const onBankClick = useCallback((bank) => {
    setBankSelected(bank);
  }, []);

  return (
    <div className="bankTransfer-container">
      {bankTransfers && (
        <>
          <div className="bankTransfer-buttons">
            {bankTransfers?.map((item) => (
              <div key={item?.name}>
                <button
                  className={`bankTransfer-button ${
                    item?.name === bankSelected?.name &&
                    "bankTransfer-button-selected"
                  }`}
                  onClick={() => onBankClick(item)}
                >
                  <div className="radioButton">
                    <input
                      type="radio"
                      checked={item?.name === bankSelected?.name}
                      id={item?.name}
                      name="radio-bank"
                    />
                    <img alt={item?.name} src={item?.logo} />
                  </div>
                </button>
              </div>
            ))}
            <>
              <button
                className={`bankTransfer-button ${
                  bankSelected?.name === "Telegraphic Transfer" &&
                  "bankTransfer-button-selected"
                }`}
                onClick={() => onBankClick({ name: "Telegraphic Transfer" })}
              >
                <div className="radioButton">
                  <input
                    type="radio"
                    checked={bankSelected?.name === "Telegraphic Transfer"}
                    name="radio-bank"
                  />
                  <label>Telegraphic Transfer</label>
                </div>
              </button>
            </>
          </div>
          {bankSelected?.name === "Telegraphic Transfer" ? (
            <TelegraphicTransfer
              country={nationality}
              invoiceNumber={invoiceNumber}
            />
          ) : (
            <div className="bankTransfer-information">
              {bankSelected?.qrCode && (
                <img
                  alt={bankSelected?.name}
                  src={bankSelected?.qrCode}
                  className="qrCode"
                />
              )}
              <div className="description">
                {bankSelected?.amount && (
                  <div className="bank-item">
                    <span className="bank-item-title">Amount: </span>
                    <span className="value">{bankSelected?.amount}</span>
                  </div>
                )}
                {bankSelected?.agentBankFeeAmount && (
                  <div className="bank-item">
                    <span className="bank-item-title">Bank Fee: </span>
                    <span className="value">
                      {bankSelected?.agentBankFeeAmount}
                    </span>
                  </div>
                )}
                {bankSelected?.totalAmount && (
                  <div className="bank-item">
                    <span className="bank-item-title">Total Amount: </span>
                    <span className="value">{bankSelected?.totalAmount}</span>
                  </div>
                )}
                {bankSelected?.accountName && (
                  <div className="bank-item">
                    <span className="bank-item-title">Account Name: </span>
                    <span className="value">{bankSelected?.accountName}</span>
                  </div>
                )}
                {bankSelected?.accountNo && (
                  <div className="bank-item">
                    <span className="bank-item-title">Account Number:</span>
                    <span className="value">{bankSelected?.accountNo}</span>
                  </div>
                )}
                {bankSelected?.bsb && (
                  <div className="bank-item">
                    <span className="bank-item-title">BSB:</span>
                    <span className="value">{bankSelected?.bsb}</span>
                  </div>
                )}
                {bankSelected?.swiftCode && (
                  <div className="bank-item">
                    <span className="bank-item-title">Swift Code:</span>
                    <span className="value">{bankSelected?.swiftCode}</span>
                  </div>
                )}
                {invoiceNumber && (
                  <div className="bank-item">
                    <span className="bank-item-title">Invoice Number:</span>
                    <span className="value">{invoiceNumber}</span>
                  </div>
                )}
                {bankSelected?.description && (
                  <div className="bank-item">
                    <span className="bank-item-title">Description:</span>
                    <span className="value">{bankSelected?.description}</span>
                  </div>
                )}
              </div>
              <div className="warning">
                * The banking transfer{" "}
                <span> description / message / ref </span> MUST match the
                invoice number '{invoiceNumber}'. Otherwise, the insurance
                cannot be issued.
              </div>
              <div className="note">
                <b>Note:</b>
                {bankSelected?.isAmountSendInvoiceVND && (
                  <span>
                    - Please complete your payment as soon as possible because{" "}
                    <b>
                      the exchange rate may be changed at 10:00am VNT everyday
                    </b>
                    .
                  </span>
                )}
                <span>
                  - The Certificate of Insurance will be uploaded to your portal
                  within 10 to 15 minutes.
                </span>
                <span>
                  - Certificate of insurance will be issued soon after the
                  payment appears in our bank account.
                </span>
                <span>
                  - Transfer from an Australian bank account to our ANZ account
                  is generally instantaneous but can take up to 24 hrs to
                  process, especially for first time transfer.
                </span>
                <span>
                  - The payment process might sometimes take longer so please
                  contact us if you don't receive the certificate of insurance
                  after 24 hours of payment.
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BankTransferCheckout;
