export const Title = {
  Mr: 0,
  Mrs: 1,
  Ms: 2,
};

export const Gender = {
  Male: 0,
  Female: 1,
  X: 2,
};

export const Relationship = {
  Partner: 0,
  Children: 1,
};

export const PaymentStepsBreadcrumbItems = {
  GetAQuote: 0,
  ApplyAQuote: 1,
  PaymentAQuote: 2,
};

export const FileType = {
  Passport: 3,
  OfferLetterOrCOE: 2,
  BirthCertificate: 11,
  COI: 10,
  IdentificationOfMotherOrFather: 7,
};
