import axios from "axios";
import { toast } from "react-toastify";
import { getObjectToQueryParams } from "../../utils/utils";

/**
 * Success response interceptor
 * @param response
 * @returns {any}
 */
const handleSuccess = (response) => {
  return response;
};

/**
 * Error response interceptor
 * @param error;
 * @returns {Promise}
 */
const handleError = (error) => {
  if (error) {
    // checkSession(error.response.status);
    // toast.error("Something went wrong");
  }
  return Promise.reject(error);
};

const service = axios.create();
service.interceptors.response.use(handleSuccess, handleError);

const getRequestHeaders = async (options) => {
  let headers = {};
  headers["Access-Control-Allow-Origin"] = "*";
  headers["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
  headers["Access-Control-Max-Age"] = 86400;
  headers["Content-Type"] = "application/json; charset=UTF-8";
  headers["Cache-Control"] =
    "no-cache,no-store,must-revalidate,max-age=-1,private";

  if (options != null && options.headers != null) {
    headers = { ...headers, ...options.headers };
  }

  return headers;
};

/**
 * Handle Response
 * @param response
 * @returns {Promise}
 */

const handleResponseData = (response) => {
  if ((!!response && response.status === 200) || response.status === 201) {
    return Promise.resolve(response.data);
  }
  return Promise.reject(response);
};

/**
 * Get request
 * @param options
 * @returns {Promise<any>}
 */

export const GET = async (path, options) => {
  const requestHeaders = await getRequestHeaders(options);
  const params = getObjectToQueryParams(options);
  const pathWithParams = options ? `${path}?${params}` : path;
  return service
    .get(pathWithParams, {
      headers: requestHeaders,
    })
    .then(
      (response) => {
        return handleResponseData(response);
      },
      (err) => {
        return handleError(err);
      }
    );
};
export const POST = async (path, payload, options) => {
  const requestHeaders = await getRequestHeaders(options);

  return service
    .request({
      method: "POST",
      url: path,
      data: payload,
      responseType: options?.responseType || "json",
      headers: requestHeaders,
    })
    .then((response) => {
      return handleResponseData(response);
    })
    .catch((err) => {
      return handleError(err);
    });
};

/**
 * PUT request
 * @param {string} path
 * @param payload
 * @param options
 * @returns {Promise<any>}
 */

export const PUT = async (path, payload, options) => {
  const requestHeaders = await getRequestHeaders(options);
  return service
    .request({
      method: "PUT",
      url: path,
      responseType: "json",
      data: payload,
      headers: requestHeaders,
    })
    .then(
      (response) => {
        return handleResponseData(response);
      },
      (err) => {
        return handleError(err);
      }
    );
};

/**
 * DELETE request
 * @param {string} path
 * @param payload
 * @param options
 * @returns {Promise<any>}
 * @constructor
 */

export const DELETE = async (path, payload, options) => {
  const requestHeaders = await getRequestHeaders(options);
  return service
    .request({
      method: "DELETE",
      url: path,
      responseType: "json",
      data: payload,
      headers: requestHeaders,
    })
    .then(
      (response) => {
        return handleResponseData(response);
      },
      (err) => {
        return handleError(err);
      }
    );
};
