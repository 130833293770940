import React, { Component } from 'react';
import '../../defaultCss/styles.css';
import SearchBar from './SearchBar';

export default class Header extends Component {
    render() {
        return (
            <header className='branding'>
                <SearchBar />
            </header>
        );
    }
}
