import IconInformation from "../assets/icon-information.svg";
import "./TelegraphicTransfer.css";
import { API_URL } from "../constants/constant";
import axios from "axios";

const TelegraphicTransfer = (props) => {
  const { invoiceNumber, country } = props;

  const openPdf = (nationality) => {
    axios
      .get(
        `${API_URL.BASE}${API_URL.DOWNLOAD_INVOICE}/${invoiceNumber}/${nationality}`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const url = URL.createObjectURL(file);
        window.open(url);
      });
  };

  return (
    <div className="telegraphic-transfer">
      <div className="wrap-imf">
        <figure id="icon-i">
          <img alt="icon-information.svg" src={IconInformation} />
        </figure>
        <div className="imf">
          <h5>How to pay by Telegraphic (Wire) Transfer</h5>
          <p>
            Telegraphic (Wire) Transfer : Telegraphic transfers are electronic
            payments of cleared funds that are credited directly to a nominated
            bank account. It's a fast and reliable way for you to make
            international payments almost anywhere in the world. Please refer to
            the following steps.
          </p>
          <p>
            Step 1: Download the relevant invoice
            <br />
            Step 2: Choose your preferred bank to make the payment.
            <br />
            Please note that all necessary documents such as the invoice,
            passport photo, offer letter must be provided to the bank.
            <br />
            Step 3: Once completed, please send us the remittance advice at
            email info@oshcstudents.com
            <br />
            We will issue the certificate as requested once receiving your
            payment.
          </p>
        </div>
      </div>
      <div className="telegraphic-transfer-action">
        <button className="link-btn" onClick={() => openPdf("ENG")}>
          English Invoice
        </button>
        {country === "VNM" && (
          <button className="link-btn" onClick={() => openPdf("VNM")}>
            Vietnamese Invoice
          </button>
        )}
        {country === "CHN" && (
          <button className="link-btn" onClick={() => openPdf("CHN")}>
            Chinese Invoice
          </button>
        )}
      </div>
    </div>
  );
};

export default TelegraphicTransfer;
