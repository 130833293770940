import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getObjectToQueryParams,
  navigateToGetAQuote,
  navigateToStudentInsurance,
} from "../../utils/utils";
import "../../defaultCss/styles.css";
import "../../defaultCss/flexslider.css";
import { PAGE_URL } from "../../constants/constant";

const StudentInsurance = () => {
  const navigate = useNavigate();

  const [searchParamsUrl] = useSearchParams();
  const agent = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentcode"),
    [searchParamsUrl]
  );
  const agentname = useMemo(
    () => new URLSearchParams(searchParamsUrl).get("agentname"),
    [searchParamsUrl]
  );

  useEffect(() => {
    const scriptJquery = document.createElement("script");
    scriptJquery.src =
      "https://d31z3rcxit7xrb.cloudfront.net/wp-includes/js/jquery/jquery.min.js?ver=3.6.1";
    scriptJquery.async = true;
    document.body.appendChild(scriptJquery);

    return () => {
      document.body.removeChild(scriptJquery);
    };
  }, []);

  const onRedirectToUrl = (quote) => {
    navigateToGetAQuote(navigate, agent, agentname, quote);
  };

  const navigateToStudentInsurancePage = () => {
    navigateToStudentInsurance(navigate, agent, agentname);
  };

  return (
    <div id="student-insurance" className="site-main">
      <div
        className="parallax intro-banner-2 single wow flipInX center animated animated"
        style={{ visibility: "visible", animationName: "flipInX" }}
      >
        <div className="background_layer">
          <div id="crumbs" className="breadcrumb">
            <span typeof="v:Breadcrumb">Australia</span>
            <span className="delimiter"></span>
            <span typeof="v:Breadcrumb">Student insurance</span>
          </div>
        </div>
      </div>
      <div id="template-oshc" className="uc">
        <div
          id="content"
          className="wow bounceInRight animated"
          style={{
            background:
              "url('https://oshcstudents.com.au/wp-content/uploads/2016/01/Untitled-1-1.jpg') center top no-repeat",
            visibility: "visible",
            animationName: "bounceInRight",
          }}
        >
          <div className="ctn_left">
            <p>
              Overseas Student Health Cover (OSHC) helps you to meet visa
              requirement.
            </p>
            <p>
              OSHC helps you pay for hospital and medical services which you may
              need while living in Australia.
            </p>
          </div>
          <div
            className="quick hvr-float-shadow"
            onClick={() => onRedirectToUrl("single")}
          >
            Quick Quote
          </div>
        </div>

        <div
          id="service-suport"
          className="wow bounceInDown center animated animated"
          style={{ visibility: "visible", animationName: "bounceInDown" }}
        >
          <div className="button-price">
            <h3 className="section-title">CHOOSE COVER TO SUIT YOUR NEED </h3>
          </div>
          <div id="flex" className="fix-width">
            <div
              className="content flexslider"
              style={{ border: "none", background: "none" }}
            >
              <div
                className="flex-viewport"
                style={{ overflow: "hidden", position: "relative" }}
              >
                <ul
                  className="slides"
                  style={{
                    width: "800%",
                    transitionDuration: "0s",
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                >
                  <li
                    className="wow slideInDown item animated"
                    style={{
                      width: "298px",
                      visibility: "visible",
                      animationName: "slideInDown",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <form>
                      <div className="service-item service-item-0">
                        <h3
                          className="clm-title-item"
                          style={{
                            background:
                              "url(https://oshcstudents.com.au/wp-content/uploads/2016/01/singleh.png) no-repeat center top",
                          }}
                        >
                          Single
                        </h3>

                        <div className="clm-content-item">
                          Cover for you only
                        </div>
                        <input
                          type="button"
                          className=""
                          name="quote"
                          value="Get A Quote"
                          onClick={() => onRedirectToUrl("single")}
                        />
                      </div>
                    </form>
                  </li>
                  <li
                    className="wow slideInDown item animated"
                    style={{
                      width: "298px",
                      visibility: "visible",
                      animationName: "slideInDown",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <form>
                      <div className="service-item service-item-1">
                        <h3
                          className="clm-title-item"
                          style={{
                            background:
                              "url(https://oshcstudents.com.au/wp-content/uploads/2016/01/dualh.png) no-repeat center top",
                          }}
                        >
                          Couple
                        </h3>

                        <div className="clm-content-item">
                          Cover for you and your partner as listed on your
                          student dependant visa
                        </div>
                        <input
                          type="button"
                          className=""
                          name="quote"
                          value="Get A Quote"
                          onClick={() => onRedirectToUrl("couple")}
                        />
                      </div>
                    </form>
                  </li>
                  <li
                    className="wow slideInDown item animated"
                    style={{
                      width: "298px",
                      visibility: "visible",
                      animationName: "slideInDown",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <form>
                      <div className="service-item service-item-2">
                        <h3
                          className="clm-title-item"
                          style={{
                            background:
                              "url('https://oshcstudents.com.au/wp-content/uploads/2016/01/grouph.png') no-repeat center top",
                          }}
                        >
                          Family
                        </h3>

                        <div className="clm-content-item">
                          Cover for you, your partner and your children under 18
                          years of age if they live with you in Australia
                        </div>
                        <input
                          type="button"
                          className=""
                          name="quote"
                          value="Get A Quote"
                          onClick={() => onRedirectToUrl("family")}
                        />
                      </div>
                    </form>
                  </li>
                  <li
                    className="wow slideInDown item animated"
                    style={{
                      width: "298px",
                      visibility: "visible",
                      animationName: "slideInDown",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <form>
                      <div className="service-item service-item-3">
                        <h3
                          className="clm-title-item"
                          style={{
                            background:
                              "url('https://oshcstudents.com.au/wp-content/uploads/2016/01/familyh.png') no-repeat center top",
                          }}
                        >
                          Single Parent
                        </h3>

                        <div className="clm-content-item">
                          Cover for you and your children under 18 years of age
                          if they live with you in Australia
                        </div>
                        <input
                          type="button"
                          className=""
                          name="quote"
                          value="Get A Quote"
                          onClick={() => onRedirectToUrl("single-parent")}
                        />
                      </div>
                    </form>
                  </li>
                </ul>
              </div>
              <ul className="flex-direction-nav">
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="flex-prev flex-disabled" href="#" tabIndex={-1}>
                    Previous
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="flex-next flex-disabled" href="#" tabIndex={-1}>
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          id="oshc-more-content"
          className="wow bounceInDown center animated animated"
          style={{ visibility: "visible", animationName: "bounceInDown" }}
        >
          <div className="fix-width">
            <div className="oshc-clm-left oshc-clm">
              <h2 className="oshc-clm-title">You are paid for </h2>
              <div className="oshc-clm-content">
                <div className="part">
                  <ul>
                    <li>Doctor visits</li>
                    <li>Specialist services</li>
                    <li>Medical treatment at hospital</li>
                    <li>Public hospital</li>
                    <li>Private hospital</li>
                  </ul>
                </div>
                <div className="part">
                  <ul>
                    <li>Emergency ambulance</li>
                    <li>Prescription medication</li>
                    <li>Prostheses</li>
                  </ul>
                </div>
                <div className="paraopacity">
                  OSHC providers pay for the following services but please
                  remember it is important that you should contact us prior to
                  any major treatments, especially staying in the hospital.
                </div>
                <div className="paraopacity">
                  <strong>Doctors*</strong>: We pay 100% of the Medicare
                  Benefits Schedule fee (MBS Fee) for General
                  Practitioners’standard consultations (doctors may charge above
                  the MBS Fee) and 85% of the MBS Fee for other out-of-hospital
                  services.
                </div>
                <div className="paraopacity">
                  <strong>Hospitals*</strong>: Including accommodation,
                  operating theatre, day services, emergency and accident
                  services, outpatient medical and post operative services.
                </div>
                <div className="paraopacity">
                  <strong>Prescription Medicines*:</strong> You pay a set amount
                  towards the cost (PBS amount) and we pay the rest, up to a
                  maximum of $50 per item to a maximum of $300 for a single
                  membership ($600 family). As an overseas student, you may face
                  significant out of pocket costs if you need treatment with
                  “high cost” pharmaceuticals, particularly oncology (cancer)
                  treatment.
                </div>
                <div className="paraopacity">
                  <strong>Pathology and X-rays*</strong>: We pay 85% of the MBS
                  Fee for out-of-hospital services or 100% of the MBS Fee for
                  in-hospital services.
                  <br />
                  Emergency ambulance transport*: We cover 100% of the cost of
                  emergency transportation in an ambulance.
                </div>
                <div className="paraopacity">
                  <strong>Prosthetic devices:</strong> We cover the full cost of
                  any No Gap prostheses and the minimum benefit for Gap
                  permitted prostheses. Surgically implanted prostheses could
                  include, for example, stents for coronary arteries, artificial
                  hips/knees or titanium plates/screws for reconstructions &amp;
                  bone breaks.
                </div>
                <div className="paraopacity">Waiting periods may apply</div>
                <div className="paraopacity">
                  Waiting periods apply for pre-existing conditions. That is, an
                  ailment, illness or condition which either you or one of your
                  dependants have, the signs or symptoms of which, in the
                  opinion of a Medical Practitioner appointed by OSHC providers,
                  existed at any time in the 6 month period before you or your
                  dependant arrived in Australia. For pre-existing medical
                  conditions and pregnancy related services including childbirth
                  there is a waiting period of 12 months. That means you won’t
                  be covered for these conditions until the waiting period is
                  over. For a pre-existing condition of a psychiatric nature,
                  there is a waiting period of 2 months.
                </div>
              </div>
              <div
                className="read-more read-more-oshc wow rollIn animated"
                data-wow-delay="1s"
                style={{
                  visibility: "visible",
                  animationDelay: "1s",
                  animationName: "rollIn",
                }}
              ></div>
            </div>
            <div className="oshc-clm-right oshc-clm">
              <h2 className="oshc-clm-title">You are not paid for </h2>
              <div className="oshc-clm-content">
                <ul>
                  <li>Infertility treatments</li>
                  <li>Services not covered by Medicare</li>
                  <li>Treatment outside of Australia</li>
                  <li>Compensation or damages</li>
                  <li>Non-essential hospital items</li>
                </ul>
                <div className="paraopacity">
                  <p>
                    WHAT YOU’RE NOT COVERED FOR
                    <br />
                    1. Services not covered by Medicare such as cosmetic surgery
                    or laser eye treatment.
                  </p>
                  <p>2. Treatment for pre-existing medical conditions.</p>
                  <p>
                    3. Any pregnancy related services in the first 12 months.
                  </p>
                  <p>4. Psychiatric services within the first 2 months.</p>
                  <p>
                    5. Assisted reproductive services such as in-vitro
                    fertilisation (IVF).
                  </p>
                  <p>
                    6. Services/treatment covered under compensation and damages
                    provisions of any kind, for example, motor vehicle accidents
                    covered by third party insurance.
                  </p>
                  <p>
                    7. Fees charged by your doctor above the Medicare Benefits
                    Schedule (MBS).
                  </p>
                  <p>
                    8. Transportation of you or your dependants into or out of
                    Australia in any circumstance.
                  </p>
                  <p>9. Treatment received outside of Australia.</p>
                  <p>
                    10. Treatment for any of your children who are over 18 years
                    of age.
                  </p>
                  <p>
                    11. Treatment by dentists or other extras services such as
                    glasses and physiotherapy unless this treatment is related
                    to a hospital stay and is included in the hospital’s
                    contract. You can be covered for this if you buy extras
                    cover.
                  </p>
                  <p>
                    12. Items provided to you on discharge from a hospital
                    including medication and crutches.
                  </p>
                  <p>
                    13. Personal expenses in hospital including telephone calls,
                    television hire, internet and newspapers.
                  </p>
                  <p>
                    14. Services or treatment for which a claim has been
                    submitted more than two years after the date of service or
                    treatment.
                  </p>
                  <p>
                    15. Treatment organised before you or your dependants
                    arrived in Australia.
                  </p>
                  <p>
                    16. Services or treatment rendered while your premiums are
                    in arrears.
                  </p>
                  <p>
                    17. The cost of prescription medicines in excess of the
                    benefit limits on we are paid for.
                  </p>
                  <p>
                    18. The gap for surgically implanted prostheses on the
                    Australian Government’s Prostheses Schedule.
                  </p>
                  <p>
                    19. Any services or items in a Partner Private Hospital that
                    are not covered by our agreement with the hospital.
                  </p>
                  <p>
                    20. The difference between the charges raised by a non
                    Partners hospital and the benefit payable by OSHC providers.
                  </p>
                  <p>21. Outpatient charges raised by private hospitals</p>
                  <p>
                    22. Surgically implanted Prostheses and other medical
                    devices not included in the Australian Government’s
                    Prostheses Schedule
                  </p>
                  <p>
                    23. Treatment rendered by providers who are not recognised
                    by OSHC providers for the purpose of paying benefits
                  </p>
                </div>
              </div>
              <div
                className="read-more read-more-oshc wow rollIn animated"
                data-wow-delay="1s"
                style={{
                  visibility: "visible",
                  animationDelay: "1s",
                  animationName: "rollIn",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div
          id="help-suport"
          className="help-suport wow bounceInLeft animated"
          style={{ visibility: "visible", animationName: "bounceInLeft" }}
        >
          <div className="fix-width">
            <h3 className="section-title">What can OSHCstudents help you?</h3>
            <div
              className="section-content"
              style={{
                display: "table",
                borderCollapse: "separate",
                borderSpacing: "15px 10px",
              }}
            >
              <div className="how" style={{ display: "table-row" }}>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://oshcstudents.com.au/issue-your-oshc-certificate-in-24-hours/"
                    className="note"
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <span>Issue a certificate in 24 hours </span>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://oshcstudents.com.au/order-the-oshc-membership-card/"
                    className="note"
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <span>Order the membership card</span>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://oshcstudents.com.au/find-a-doctor-of-oshc/"
                    className="note"
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <span>Find a doctor</span>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://oshcstudents.com.au/oshc-how-to-make-a-claim/"
                    className="note"
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <span>Claim</span>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://oshcstudents.com.au/change-your-oshc-policy/"
                    className="note"
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <span>Change a cover</span>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://oshcstudents.com.au/find-an-extra-oshc-cover-for-you/"
                    className="note"
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <span>Find an extra cover</span>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://oshcstudents.com.au/australia-student-insurance-oshc-qa/"
                    className="note"
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <span>Q&amp;A</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
        <div id="quick-quote" className="quick-quote oshc">
          <div className="fix-width quoke">
            <div
              className="quote-content wow zoomInRight animated"
              style={{ visibility: "visible", animationName: "zoomInRight" }}
            >
              <ul>
                <li>OSHC protects yourself from the unexpected.</li>
                <li>
                  OSHC gives you convenience and peace of mind while you’re
                  studying in Australia.
                </li>
                <li>
                  <strong>OSHCstudents</strong> connects you to the best OSHC.
                </li>
              </ul>
            </div>
            <div
              className="thumb quote-content  wow zoomInLeft animated"
              style={{
                height: "198px",
                visibility: "visible",
                animationName: "zoomInLeft",
              }}
            >
              <div
                className="quote-image"
                style={{
                  background:
                    'url("https://oshcstudents.com.au/wp-content/uploads/2016/01/Layer-102.png") no-repeat center center',
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div
              className="text wow rollIn animated"
              data-wow-delay="1s"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "rollIn",
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="quick-quote-goto" href="">
                <span className="">Quick Quote</span>
              </a>
            </div>
          </div>
        </div>

        <section id="reviews" className="">
          <div className="background-opacity">
            <div className="fix-width wrap-content fix-width-other">
              <div
                className="flex-viewport"
                style={{ overflow: "hidden", position: "relative" }}
              >
                <div
                  className="flexslider slides listing-reviews"
                  style={{
                    width: "1400%",
                    transitionDuration: "0.6s",
                    transform: "translate3d(-4468.56px, 0px, 0px)",
                  }}
                >
                  <div
                    className="slide"
                    style={{
                      width: "893.713px",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <article className="entry review post-637 type-review status-publish has-post-thumbnail hentry">
                      <div className="thumb">
                        <img
                          className="lazy"
                          src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Ngoc-Le.jpg"
                          data-src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Ngoc-Le.jpg"
                          alt="Baek Ah Yeon"
                          draggable="false"
                        />
                      </div>
                      <div className="content">
                        <p>
                          I trust Allianz Care since it is one of the largest
                          insurance companies in Australia, with the medical
                          centres where I only have to pay for what is left from
                          insurance support. (no need to claim later). Besides,
                          those centres are located at the centre of the city,
                          close to schools. Very convenient, right? Credits to
                          Allianz Care for providing reliable medical centres
                          for health check and treatments.
                        </p>
                        <p className="profile">
                          <span className="name">Baek Ah Yeon</span> |
                          <span className="office">
                            University of Queensland
                          </span>
                        </p>
                      </div>
                    </article>
                  </div>
                  <div
                    className="slide"
                    style={{
                      width: "893.713px",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <article className="entry review post-633 type-review status-publish has-post-thumbnail hentry">
                      <div className="thumb">
                        <img
                          className="lazy"
                          src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Ariella-Pei.jpg"
                          data-src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Ariella-Pei.jpg"
                          alt="Ariella Pei"
                          draggable="false"
                        />
                      </div>
                      <div className="content">
                        <p>
                          I’ve been living with homestay family for 2 years. I’m
                          now 19, I can move out but I don’t want to leave my
                          host, who has become my family. It feels like I’ve met
                          my second family. Thanks OSHCstudents for sending me
                          to this homestay!
                        </p>
                        <p className="profile">
                          <span className="name">Ariella Pei</span> |
                          <span className="office">University of Canberra</span>
                        </p>
                      </div>
                    </article>
                  </div>
                  <div
                    className="slide"
                    style={{
                      width: "893.713px",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <article className="entry review post-548 type-review status-publish has-post-thumbnail hentry">
                      <div className="thumb">
                        <img
                          className="lazy"
                          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                          data-src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Benben.jpg"
                          alt="Chatchawat Paton"
                          draggable="false"
                        />
                      </div>
                      <div className="content">
                        <p>
                          I realised how important insurance was when I started
                          living in Australia. There I switched to using Allianz
                          Care instead of the previous service. Allianz Care is
                          extraordinarily good in its consulting service and
                          customer care. I’ve learned a lot about Australian
                          health care, also I was consulted with mental issues
                          and how to live a healthy lifestyle. I am very happy
                          now.
                        </p>
                        <p className="profile">
                          <span className="name">Chatchawat Paton</span> |
                          <span className="office">Torrent University</span>
                        </p>
                      </div>
                    </article>
                  </div>
                  <div
                    className="slide"
                    style={{
                      width: "893.713px",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <article className="entry review post-546 type-review status-publish has-post-thumbnail hentry">
                      <div className="thumb">
                        <img
                          className="lazy"
                          src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Phuong-Anh.jpg"
                          data-src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Phuong-Anh.jpg"
                          alt="Phuong Anh"
                          draggable="false"
                        />
                      </div>
                      <div className="content">
                        <p>
                          Having lived in Australia for 2 years, I’ve been
                          reliant on AHM OSHC to pay for my health care, which
                          supports me for other expenses. Last year, I sent my
                          claim requests for several times and always received
                          the claims within two days. Everything is easy, fast
                          and simple.
                        </p>
                        <p className="profile">
                          <span className="name">Phuong Anh</span> |
                          <span className="office">Victoria University</span>
                        </p>
                      </div>
                    </article>
                  </div>
                  <div
                    className="slide"
                    style={{
                      width: "893.713px",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <article className="entry review post-543 type-review status-publish has-post-thumbnail hentry">
                      <div className="thumb">
                        <img
                          className="lazy"
                          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                          data-src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Xiaoyu-Cheng.jpg"
                          alt="Xiaoyu Cheng"
                          draggable="false"
                        />
                      </div>
                      <div className="content">
                        <p>
                          Thanks to Allianz Care, every time I claimed my
                          medical receipts it processed much more quickly and
                          easily than I expected. I was initially a bit
                          concerned since Allianz Care is more expensive than
                          other insurance companies, but now I can ensure that
                          it is totally worth the money.
                        </p>
                        <p className="profile">
                          <span className="name">Xiaoyu Cheng</span> |
                          <span className="office">Monash University</span>
                        </p>
                      </div>
                    </article>
                  </div>
                  <div
                    className="slide"
                    style={{
                      width: "893.713px",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <article className="entry review post-105 type-review status-publish has-post-thumbnail hentry">
                      <div className="thumb">
                        <img
                          className="lazy"
                          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                          data-src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Emma.jpg"
                          alt="Emma"
                          draggable="false"
                        />
                      </div>
                      <div className="content">
                        <p>
                          My homestay was very comfortable and convenient. My
                          host was really kind. He showed me where to shop,
                          which buses to catch, where the nearest train station
                          is, to get off at which station so that I wouldn’t be
                          taken to another suburb. Travelling was easy since the
                          place was close to public transports. It only took 30
                          minutes to get to my college, actually I could go
                          anywhere easily!
                        </p>
                        <p className="profile">
                          <span className="name">Emma</span> |
                          <span className="office">Macquarie University</span>
                        </p>
                      </div>
                    </article>
                  </div>
                  <div
                    className="slide"
                    style={{
                      width: "893.713px",
                      float: "left",
                      display: "block",
                    }}
                  >
                    <article className="entry review post-103 type-review status-publish has-post-thumbnail hentry">
                      <div className="thumb">
                        <img
                          className="lazy"
                          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                          data-src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Jing-Zhang.jpg"
                          alt="Jing Zhang"
                          draggable="false"
                        />
                      </div>
                      <div className="content">
                        <p>
                          When told that I was going to live with an Italian
                          family, I was pretty nervous and doubted whether or
                          not they could speak English well as the local people?
                          Yet it was no longer a problem when I saw them. They
                          knew the problems of people who first came to
                          Australia and always tried to help me. I learned so
                          many things about both Australian and Italian culture
                          and enjoyed tasty Italian meals every time. Awesome!
                        </p>
                        <p className="profile">
                          <span className="name">Jing Zhang</span> |
                          <span className="office">Griffith University</span>
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <ul className="flex-direction-nav">
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="flex-prev" href="#">
                    Previous
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="flex-next" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div className="fix-width wrap-content">
          <section
            className="services wow slideInLeft animated"
            style={{ visibility: "visible", animationName: "slideInLeft" }}
          >
            <h3 className="section-title">OSHCstudents Services</h3>
            <div className="desc"></div>

            <ul id="horiz_container_outer" className=" listing-services">
              <li id="horiz_container_inner">
                <div className="content flexslider">
                  <div
                    className="flex-viewport"
                    style={{ overflow: "hidden", position: "relative" }}
                  >
                    <ul
                      id="horiz_container"
                      className="slides"
                      style={{
                        width: "800%",
                        transitionDuration: "0.4s",
                        transform: "translate3d(0px, 0px, 0px)",
                      }}
                    >
                      <li
                        id="cat-3"
                        className="service-category slide item"
                        style={{
                          width: "400px",
                          float: "left",
                          display: "block",
                        }}
                      >
                        <h2 className="entry-title">Australia</h2>
                        <div className="wap">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            className="thumb"
                            href="#"
                            title="Australia"
                            rel="bookmark"
                          >
                            <img
                              className="lazy"
                              src="https://oshcstudents.com.au/wp-content/uploads/2016/01/11.07.18-bcp_ft_australia-3_17191420_582_388.png"
                              data-src="https://oshcstudents.com.au/wp-content/uploads/2016/01/11.07.18-bcp_ft_australia-3_17191420_582_388.png"
                              alt="Australia"
                              draggable="false"
                            />
                          </a>

                          <div className="excerpt">
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s1.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">Student insurance</div>
                              <span
                                className="quote"
                                style={{ cursor: "pointer" }}
                                onClick={navigateToStudentInsurancePage}
                              >
                                GET A QUOTE
                              </span>
                            </div>
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s2.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">Visitor insurance</div>
                              <a
                                href="https://oshcstudents.com.au/visitor-and-work-insurance-australia/"
                                className="quote"
                              >
                                GET A QUOTE
                              </a>
                            </div>
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s6.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">
                                Sim cards &amp; Phones
                              </div>
                              <a
                                href="https://oshcstudents.com.au/sim-cards-phones/"
                                className="quote"
                              >
                                GET A QUOTE
                              </a>
                            </div>
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s6.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">Bank Account</div>
                              <a
                                href="https://oshcstudents.com.au/bank-account/"
                                className="quote"
                              >
                                GET A QUOTE
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        id="cat-2"
                        className="service-category slide item"
                        style={{
                          width: "400px",
                          float: "left",
                          display: "block",
                        }}
                      >
                        <h2 className="entry-title">New Zealand</h2>
                        <div className="wap">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            className="thumb"
                            href="#"
                            title="New Zealand"
                            rel="bookmark"
                          >
                            <img
                              className="lazy"
                              src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Layer-56.jpg"
                              data-src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Layer-56.jpg"
                              alt="New Zealand"
                              draggable="false"
                            />
                          </a>

                          <div className="excerpt">
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s1.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">Student insurance</div>
                              <a
                                href="https://oshcstudents.com.au/student-insurance-nz/"
                                className="quote"
                              >
                                GET A QUOTE
                              </a>
                            </div>
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s2.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">Visitor Insurance</div>
                              <a
                                href="https://oshcstudents.com.au/visitor-and-work-insurance-newzealand/"
                                className="quote"
                              >
                                GET A QUOTE
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        id="cat-4"
                        className="service-category slide item"
                        style={{
                          width: "400px",
                          float: "left",
                          display: "block",
                        }}
                      >
                        <h2 className="entry-title">USA</h2>
                        <div className="wap">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            className="thumb"
                            href="#"
                            title="USA"
                            rel="bookmark"
                          >
                            <img
                              className="lazy"
                              src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Untitled-1.jpg"
                              data-src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Untitled-1.jpg"
                              alt="USA"
                              draggable="false"
                            />
                          </a>

                          <div className="excerpt">
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s1.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">Student insurance</div>
                              <a
                                href="https://oshcstudents.com.au/student-insurance-usa/"
                                className="quote"
                              >
                                GET A QUOTE
                              </a>
                            </div>
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s2.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">Visitor Insurance</div>
                              <a
                                href="https://oshcstudents.com.au/visitor-and-work-insurance-usa/"
                                className="quote"
                              >
                                GET A QUOTE
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        id="cat-6"
                        className="service-category slide item"
                        style={{
                          width: "400px",
                          float: "left",
                          display: "block",
                        }}
                      >
                        <h2 className="entry-title">Canada</h2>
                        <div className="wap">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            className="thumb"
                            href="#"
                            title="Canada"
                            rel="bookmark"
                          >
                            <img
                              className="lazy"
                              src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Toronto_1.jpg"
                              data-src="https://d31z3rcxit7xrb.cloudfront.net/wp-content/uploads/2016/01/Toronto_1.jpg"
                              alt="Canada"
                              draggable="false"
                            />
                          </a>

                          <div className="excerpt">
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s1.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">Student insurance</div>
                              <a
                                href="https://oshcstudents.com.au/student-insurance-canada/"
                                className="quote"
                              >
                                GET A QUOTE
                              </a>
                            </div>
                            <div
                              className="row"
                              style={{
                                background:
                                  "rgba(0, 0, 0, 0) url('https://oshcstudents.com.au/wp-content/uploads/2016/02/s2.png') no-repeat scroll left center",
                              }}
                            >
                              <div className="title">Visitor Insurance</div>
                              <a
                                href="https://oshcstudents.com.au/visitor-insurance-canada/"
                                className="quote"
                              >
                                GET A QUOTE
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <ul className="flex-direction-nav">
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a className="flex-prev" href="#">
                        Previous
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a className="flex-next" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default connect()(StudentInsurance);
