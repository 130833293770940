import { useSelector } from "react-redux";
import moment from "moment";
import { DEFAULT_FORMAT_DATE } from "../constants/constant";
import { useState, useMemo, useEffect } from "react";

const PolicySummary = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const { data } = props;
  const providerList = useSelector(
    (store) => store.getAQuoteReducer.providerList
  );

  const selectedProvider = useMemo(
    () => providerList.find((pro) => pro.providerId === data.providerId),
    [data.providerId, providerList]
  );

  return (
    <>
      <div className="policy-summary-header">
        <h4 className="form-title">POLICY SUMMARY</h4>
        {/* {props.showEndSessionBtn && (
          <input
            type="submit"
            value="<<  End Payment Session"
            className="wpcf7-form-control wpcf7-submit policy-summary-end-btn"
            id="savequote_submit"
            onClick={props.endSession}
          ></input>
        )} */}
      </div>
      {!isMobile ? (
        <table className="imf border summary">
          <thead>
            <tr>
              <th>OSHC provider </th>
              <th>Start date</th>
              <th>End date</th>
              <th>No of Adults</th>
              <th>No of children</th>
              <th>Price (in AU dollars)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="center_apply" data-label="">
                <img
                  className="lazy img_logo"
                  alt="img logo"
                  src={selectedProvider != null ? selectedProvider.logoUrl : ""}
                  data-src={
                    selectedProvider != null ? selectedProvider.logoUrl : ""
                  }
                  loading="lazy"
                />
              </td>

              <td className="center_apply" data-label="Start date :">
                {moment(new Date(data.startDate)).format(DEFAULT_FORMAT_DATE)}
              </td>
              <td className="center_apply" data-label="End date :">
                {moment(new Date(data.endDate)).format(DEFAULT_FORMAT_DATE)}
              </td>
              <td className="center_apply" data-label="No of Adults:">
                {data.numberOfAdults}
              </td>
              <td className="center_apply" data-label="No of children :">
                {data.numberOfChildren}
              </td>
              <td className="center_apply" data-label="Price (in AU dollars) :">
                {selectedProvider != null && !selectedProvider.loading ? (
                  selectedProvider.price.toFixed(2)
                ) : (
                  <img
                    id={selectedProvider?.priceLoadingImgId}
                    className="lazy loading_oshc"
                    src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Loading_icon_cropped.gif"
                    data-src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Loading_icon_cropped.gif"
                    style={{
                      height: "20px",
                    }}
                    alt="loading icon cropped"
                    loading="lazy"
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table className="imf border summary stacktable small-only">
          <tbody>
            <tr className="  ">
              <th className="st-head-row st-head-row-main" colSpan="2">
                OSHC provider
              </th>
            </tr>
            <tr className="">
              <th className="st-head-row" colSpan="2">
                <img
                  className="lazy img_logo"
                  src={selectedProvider != null ? selectedProvider.logoUrl : ""}
                  data-src={
                    selectedProvider != null ? selectedProvider.logoUrl : ""
                  }
                ></img>
              </th>
            </tr>
            <tr className="">
              <td className="st-key">Start date</td>
              <td className="st-val center_apply">
                {moment(new Date(data.startDate)).format(DEFAULT_FORMAT_DATE)}
              </td>
            </tr>
            <tr className="">
              <td className="st-key">End date</td>
              <td className="st-val center_apply">
                {moment(new Date(data.endDate)).format(DEFAULT_FORMAT_DATE)}
              </td>
            </tr>
            <tr className="">
              <td className="st-key">No of Adults</td>
              <td className="st-val center_apply">{data.numberOfAdults}</td>
            </tr>
            <tr className="">
              <td className="st-key">No of children</td>
              <td className="st-val center_apply">{data.numberOfChildren}</td>
            </tr>
            <tr className="">
              <td className="st-key">Price (in AU dollars)</td>
              <td className="st-val center_apply">
                {selectedProvider != null && !selectedProvider.loading ? (
                  selectedProvider.price.toFixed(2)
                ) : (
                  <img
                    id={selectedProvider?.priceLoadingImgId}
                    className="lazy loading_oshc"
                    src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Loading_icon_cropped.gif"
                    data-src="https://oshcstudents.com.au/wp-content/uploads/2016/01/Loading_icon_cropped.gif"
                    style={{
                      height: "20px",
                    }}
                    alt="loading icon cropped"
                    loading="lazy"
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default PolicySummary;
