import { call, put, takeEvery } from "redux-saga/effects";
import { API_URL, PROVIDER_ID } from "../constants/constant";
import {
  fetchCommonRequest,
  fetchCommonSuccess,
  fetchCommonFailure,
} from "../reducers/reducer-common";
import ApiService from "../services/api/apiService";

export function* commonSaga() {
  yield takeEvery(fetchCommonRequest.type, fetchCommonSaga);
}

function* fetchCommonSaga(action) {
  try {
    let { providerId, agent } = action.payload;
    const nationality = yield call(() =>
      ApiService.get(API_URL.GET_NATIONALITY, { agent, providerId })
    );

    let institution = null;
    if (providerId === PROVIDER_ID.ALLIANZ) {
      institution = yield call(() =>
        ApiService.get(API_URL.GET_INSTITUTION, { agent })
      );
    }

    if (nationality && (providerId !== PROVIDER_ID.ALLIANZ || institution)) {
      yield put(
        fetchCommonSuccess({
          nationality: nationality,
          institution: institution,
        })
      );
    }
  } catch (error) {
    yield put(fetchCommonFailure(error.message));
  }
}
